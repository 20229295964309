import Symbol_observable from 'symbol-observable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { configureStore } from '@reduxjs/toolkit';
import clueReducer from "./store/gameReducer";
import authReducer from "./store/authSlice";

const mode = process.env.REACT_APP_ENV;
  if(mode === "PROD") {
    console.log = function() {}
  } 

const store = configureStore({
  reducer : {
    game : clueReducer,
    auth : authReducer,
  },
  middleware : (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck : false
  })
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

